import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "@babel/runtime/helpers/esm/objectSpread";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _has from "lodash/has";
export function getIndexId(context) {
  return context && context.multiIndexContext ? context.multiIndexContext.targetedIndex : context.ais.mainTargetedIndex;
}
export function getResults(searchResults, context) {
  if (searchResults.results && !searchResults.results.hits) {
    return searchResults.results[getIndexId(context)] ? searchResults.results[getIndexId(context)] : null;
  } else {
    return searchResults.results ? searchResults.results : null;
  }
}
export function hasMultipleIndices(context) {
  return context && context.multiIndexContext;
} // eslint-disable-next-line max-params

export function refineValue(searchState, nextRefinement, context, resetPage, namespace) {
  if (hasMultipleIndices(context)) {
    return namespace ? refineMultiIndexWithNamespace(searchState, nextRefinement, context, resetPage, namespace) : refineMultiIndex(searchState, nextRefinement, context, resetPage);
  } else {
    // When we have a multi index page with shared widgets we should also
    // reset their page to 1 if the resetPage is provided. Otherwise the
    // indices will always be reset
    // see: https://github.com/algolia/react-instantsearch/issues/310
    // see: https://github.com/algolia/react-instantsearch/issues/637
    if (searchState.indices && resetPage) {
      Object.keys(searchState.indices).forEach(function (targetedIndex) {
        searchState = refineValue(searchState, {
          page: 1
        }, {
          multiIndexContext: {
            targetedIndex: targetedIndex
          }
        }, true, namespace);
      });
    }

    return namespace ? refineSingleIndexWithNamespace(searchState, nextRefinement, resetPage, namespace) : refineSingleIndex(searchState, nextRefinement, resetPage);
  }
}

function refineMultiIndex(searchState, nextRefinement, context, resetPage) {
  var page = resetPage ? {
    page: 1
  } : undefined;
  var indexId = getIndexId(context);
  var state = _has(searchState, "indices.".concat(indexId)) ? _objectSpread({}, searchState.indices, _defineProperty({}, indexId, _objectSpread({}, searchState.indices[indexId], nextRefinement, page))) : _objectSpread({}, searchState.indices, _defineProperty({}, indexId, _objectSpread({}, nextRefinement, page)));
  return _objectSpread({}, searchState, {
    indices: state
  });
}

function refineSingleIndex(searchState, nextRefinement, resetPage) {
  var page = resetPage ? {
    page: 1
  } : undefined;
  return _objectSpread({}, searchState, nextRefinement, page);
} // eslint-disable-next-line max-params


function refineMultiIndexWithNamespace(searchState, nextRefinement, context, resetPage, namespace) {
  var _objectSpread4;

  var indexId = getIndexId(context);
  var page = resetPage ? {
    page: 1
  } : undefined;
  var state = _has(searchState, "indices.".concat(indexId)) ? _objectSpread({}, searchState.indices, _defineProperty({}, indexId, _objectSpread({}, searchState.indices[indexId], (_objectSpread4 = {}, _defineProperty(_objectSpread4, namespace, _objectSpread({}, searchState.indices[indexId][namespace], nextRefinement)), _defineProperty(_objectSpread4, "page", 1), _objectSpread4)))) : _objectSpread({}, searchState.indices, _defineProperty({}, indexId, _objectSpread(_defineProperty({}, namespace, nextRefinement), page)));
  return _objectSpread({}, searchState, {
    indices: state
  });
}

function refineSingleIndexWithNamespace(searchState, nextRefinement, resetPage, namespace) {
  var page = resetPage ? {
    page: 1
  } : undefined;
  return _objectSpread({}, searchState, _defineProperty({}, namespace, _objectSpread({}, searchState[namespace], nextRefinement)), page);
}

function getNamespaceAndAttributeName(id) {
  var parts = id.match(/^([^.]*)\.(.*)/);
  var namespace = parts && parts[1];
  var attributeName = parts && parts[2];
  return {
    namespace: namespace,
    attributeName: attributeName
  };
} // eslint-disable-next-line max-params


export function getCurrentRefinementValue(props, searchState, context, id, defaultValue) {
  var refinementsCallback = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : function (x) {
    return x;
  };
  var indexId = getIndexId(context);

  var _getNamespaceAndAttri = getNamespaceAndAttributeName(id),
      namespace = _getNamespaceAndAttri.namespace,
      attributeName = _getNamespaceAndAttri.attributeName;

  var refinements = hasMultipleIndices(context) && searchState.indices && namespace && searchState.indices["".concat(indexId)] && _has(searchState.indices["".concat(indexId)][namespace], "".concat(attributeName)) || hasMultipleIndices(context) && searchState.indices && _has(searchState, "indices.".concat(indexId, ".").concat(id)) || !hasMultipleIndices(context) && namespace && _has(searchState[namespace], attributeName) || !hasMultipleIndices(context) && _has(searchState, id);

  if (refinements) {
    var currentRefinement;

    if (hasMultipleIndices(context)) {
      currentRefinement = namespace ? _get(searchState.indices["".concat(indexId)][namespace], attributeName) : _get(searchState.indices[indexId], id);
    } else {
      currentRefinement = namespace ? _get(searchState[namespace], attributeName) : _get(searchState, id);
    }

    return refinementsCallback(currentRefinement);
  }

  if (props.defaultRefinement) {
    return props.defaultRefinement;
  }

  return defaultValue;
}
export function cleanUpValue(searchState, context, id) {
  var indexId = getIndexId(context);

  var _getNamespaceAndAttri2 = getNamespaceAndAttributeName(id),
      namespace = _getNamespaceAndAttri2.namespace,
      attributeName = _getNamespaceAndAttri2.attributeName;

  if (hasMultipleIndices(context) && Boolean(searchState.indices)) {
    return cleanUpValueWithMutliIndex({
      attribute: attributeName,
      searchState: searchState,
      indexId: indexId,
      id: id,
      namespace: namespace
    });
  }

  return cleanUpValueWithSingleIndex({
    attribute: attributeName,
    searchState: searchState,
    id: id,
    namespace: namespace
  });
}

function cleanUpValueWithSingleIndex(_ref) {
  var searchState = _ref.searchState,
      id = _ref.id,
      namespace = _ref.namespace,
      attribute = _ref.attribute;

  if (namespace) {
    return _objectSpread({}, searchState, _defineProperty({}, namespace, _omit(searchState[namespace], attribute)));
  }

  return _omit(searchState, id);
}

function cleanUpValueWithMutliIndex(_ref2) {
  var searchState = _ref2.searchState,
      indexId = _ref2.indexId,
      id = _ref2.id,
      namespace = _ref2.namespace,
      attribute = _ref2.attribute;
  var indexSearchState = searchState.indices[indexId];

  if (namespace && indexSearchState) {
    return _objectSpread({}, searchState, {
      indices: _objectSpread({}, searchState.indices, _defineProperty({}, indexId, _objectSpread({}, indexSearchState, _defineProperty({}, namespace, _omit(indexSearchState[namespace], attribute)))))
    });
  }

  return _omit(searchState, "indices.".concat(indexId, ".").concat(id));
}